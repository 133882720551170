<template>
  <div>
    <template v-if="d_frontendList.length > 0">
      <b-row style="font-size: 12px;">
        <b-col sm="12" lg="1"></b-col>
        <b-col sm="12" lg="10">
          <b-row style="margin: 3px;">
            <b-col sm="12" lg="4">
              {{ StoreLangTranslation.data['frontend_list'][StoreLang] }}
            </b-col>
            <b-col sm="12" lg="8">
              <select class="form-control" v-model="d_frontendClientID">
                <option v-for="(frd, frd_index) in d_frontendList" :value="frd.client_id">
                  {{ frd.w_id }} / {{ frd.client_id }}
                </option>
              </select>
            </b-col>
          </b-row>
          <b-row v-if="d_frontendClientID" style="margin-bottom: 10px;">
            <b-col sm="12" lg="6"> </b-col>
            <b-col sm="12" lg="3" style="text-align: right;">
              <b-button size="sm" @click="d_systemUpdateByExcelModal = true" variant="outline-primary">
              {{ StoreLangTranslation.data['system_update_by_excel_file'][StoreLang] }}
              </b-button>
            </b-col>
            <b-col sm="12" lg="3" style="text-align: right;">
              <b-button size="sm" @click="f_newWebsiteUnit()" variant="outline-primary">
              {{ StoreLangTranslation.data['new_websiteunit'][StoreLang] }}
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12" lg="12">
              <b-form-input id="search" v-model="d_searchText" placeholder="Filtrelemek için yazınız."></b-form-input>
            </b-col>
          </b-row>
          <b-row style="margin: 3px;">
            <b-col sm="12" lg="1">
              <strong> Id </strong>
            </b-col>
            <b-col sm="12" lg="1">
              <strong> {{ d_frdWebUnitWdm['0']['parameters']['0']['name']['translation'][StoreLang] }} </strong>
            </b-col>
            <b-col sm="12" lg="1">
              <strong> {{ d_frdWebUnitWdm['0']['parameters']['1']['name']['translation'][StoreLang] }} </strong>
            </b-col>
            <b-col sm="12" lg="1">
              <strong> {{ d_frdWebUnitWdm['0']['parameters']['2']['name']['translation'][StoreLang] }} </strong>
            </b-col>
            <b-col sm="12" lg="1">
              <strong> {{ d_frdWebUnitWdm['0']['parameters']['3']['name']['translation'][StoreLang] }} </strong>
            </b-col>
            <b-col sm="12" lg="1">
              <strong> {{ d_frdWebUnitWdm['0']['parameters']['4']['name']['translation'][StoreLang] }} </strong>
            </b-col>
            <b-col sm="12" lg="1">
              <strong> {{ d_frdWebUnitWdm['0']['parameters']['5']['name']['translation'][StoreLang] }} </strong>
            </b-col>
            <b-col sm="12" lg="1">
              <strong> {{ d_frdWebUnitWdm['0']['parameters']['6']['name']['translation'][StoreLang] }} </strong>
            </b-col>
            <b-col sm="12" lg="1">
              <strong> {{ d_frdWebUnitWdm['0']['parameters']['23']['name']['translation'][StoreLang] }} </strong>
            </b-col>
            <b-col sm="12" lg="1">
              <strong> {{ d_frdWebUnitWdm['0']['parameters']['24']['name']['translation'][StoreLang] }} </strong>
            </b-col>
            <b-col sm="12" lg="2">
            </b-col>
          </b-row>
          <b-row v-for="(website_unit, website_unit_ind) in d_frontendWebsiteunitList" v-if="f_searchWebsiteUnit(website_unit)" @mouseover="d_hoverIndex = website_unit_ind" @mouseleave="d_hoverIndex = ''" :style="StoreDevice.isMobile ? 'cursor: pointer; margin: 10px; border-bottom: solid 1px #dcdcdc;' : 'cursor: pointer; margin: 1px; border-bottom: solid 1px #dcdcdc;'">
            <b-col sm="12" lg="1">
              <div> <strong> {{ website_unit_ind + 1 }} ) </strong> </div>
              <small> {{ website_unit.client_id }} </small>
            </b-col>
            <b-col sm="12" lg="1">
              <template v-if="website_unit.data && website_unit.data['0'] && website_unit.data['0']['0'] && website_unit.data['0']['0']['val']">
                <span :style="d_hoverIndex === website_unit_ind ? ' color: rgb(252, 0, 153, 1);' : ''">{{ website_unit.data['0']['0']['val']['value'] }}</span>
              </template>
            </b-col>
            <b-col sm="12" lg="1">
              <template v-if="website_unit.data && website_unit.data['0'] && website_unit.data['0']['1'] && website_unit.data['0']['1']['val']">
                <span :style="d_hoverIndex === website_unit_ind ? ' color: rgb(252, 0, 153, 1);' : ''">{{ website_unit.data['0']['1']['val']['value'] }}</span>
              </template>
            </b-col>
            <b-col sm="12" lg="1">
              <template v-if="website_unit.data && website_unit.data['0'] && website_unit.data['0']['2'] && website_unit.data['0']['2']['lang'] && website_unit.data['0']['2']['lang'][StoreLang]">
                <span :style="d_hoverIndex === website_unit_ind ? ' color: rgb(252, 0, 153, 1);' : ''">{{ website_unit.data['0']['2']['lang'][StoreLang] }}</span>
              </template>
              <template v-else-if="website_unit.data && website_unit.data['0'] && website_unit.data['0']['2'] && website_unit.data['0']['2']['val']">
                <span :style="d_hoverIndex === website_unit_ind ? ' color: rgb(252, 0, 153, 1);' : ''">{{ website_unit.data['0']['2']['val'] }}</span>
              </template>
            </b-col>
            <b-col sm="12" lg="1">
              <template v-if="website_unit.data && website_unit.data['0'] && website_unit.data['0']['3'] && website_unit.data['0']['3']['val']['value']">
                <span :style="d_hoverIndex === website_unit_ind ? ' color: rgb(252, 0, 153, 1);' : ''">{{ website_unit.data['0']['3']['val']['value'] }}</span>
              </template>
            </b-col>
            <b-col sm="12" lg="1">
              <template v-if="website_unit.data && website_unit.data['0'] && website_unit.data['0']['4'] && website_unit.data['0']['4']['lang'] && website_unit.data['0']['4']['lang'][StoreLang]">
                <span :style="d_hoverIndex === website_unit_ind ? ' color: rgb(252, 0, 153, 1);' : ''">{{ website_unit.data['0']['4']['lang'][StoreLang] }}</span>
              </template>
              <template v-else-if="website_unit.data && website_unit.data['0'] && website_unit.data['0']['4'] && website_unit.data['0']['4']['val']">
                <span :style="d_hoverIndex === website_unit_ind ? ' color: rgb(252, 0, 153, 1);' : ''">{{ website_unit.data['0']['4']['val'] }}</span>
              </template>
            </b-col>
            <b-col sm="12" lg="1">
              <template v-if="website_unit.data && website_unit.data['0'] && website_unit.data['0']['5'] && website_unit.data['0']['5']['lang'] && website_unit.data['0']['5']['lang'][StoreLang]">
                <span :style="d_hoverIndex === website_unit_ind ? ' color: rgb(252, 0, 153, 1);' : ''">{{ website_unit.data['0']['5']['lang'][StoreLang] }}</span>
              </template>
              <template v-else-if="website_unit.data && website_unit.data['0'] && website_unit.data['0']['5'] && website_unit.data['0']['5']['val']">
                <span :style="d_hoverIndex === website_unit_ind ? ' color: rgb(252, 0, 153, 1);' : ''">{{ website_unit.data['0']['5']['val'] }}</span>
              </template>
            </b-col>
            <b-col sm="12" lg="1">
              <template v-if="website_unit.data && website_unit.data['0'] && website_unit.data['0']['6'] && website_unit.data['0']['6']['val']">
                <span :style="d_hoverIndex === website_unit_ind ? ' color: rgb(252, 0, 153, 1);' : ''">{{ website_unit.data['0']['6']['val']['value'] }}</span>
              </template>
            </b-col>
            <b-col sm="12" lg="1">
              <template v-if="website_unit.data && website_unit.data['0'] && website_unit.data['0']['23'] && website_unit.data['0']['23']['lang']">
                <span :style="d_hoverIndex === website_unit_ind ? ' color: rgb(252, 0, 153, 1);' : ''">
                {{ website_unit.data['0']['23']['lang'][StoreLang] }}
              </span>
              </template>
            </b-col>
            <b-col sm="12" lg="1">
              <template v-if="website_unit.data && website_unit.data['0'] && website_unit.data['0']['24'] && website_unit.data['0']['24']['lang']">
                <span :style="d_hoverIndex === website_unit_ind ? ' color: rgb(252, 0, 153, 1);' : ''">{{ website_unit.data['0']['24']['lang'][StoreLang] }}</span>
              </template>
            </b-col>
            <b-col sm="12" lg="2">
              <b-dropdown variant="white" size="sm" style="width: 100%; float: left;">
                <template slot="button-content">
                  işlem
                </template>
                <b-dropdown-item v-if="d_user && d_user.auth.indexOf('admin') !== -1" @click="f_editThisWebsiteUnit(website_unit.client_id)"><i class="fa fa-edit"></i> düzenle </b-dropdown-item>
              </b-dropdown>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </template>
    <b-modal v-if="d_systemUpdateByExcelModal" id="d_selectExcelData_show" v-model="d_systemUpdateByExcelModal" scrollable hide-footer :title="StoreLangTranslation.data['process'][StoreLang]" centered header-bg-variant="info" header-text-variant="dark" size="xl">
      <p>
        <b-row>
          <b-col sm="12" lg="12">
            <b-form-file
                @input="f_selectExcelDocument()"
                style="height: 100px; border: dotted 2px #25bcff; background: #daf9ff;"
                v-model="d_tmpFile"
                :accept="f_createAcceptedDocumentTypes()"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
            >
              <img src="@/icon/1530793.png" style="width: 2em;">
            </b-form-file>
          </b-col>
        </b-row>
      </p>
      <p>
        <b-row>
          <b-col sm="12" lg="6">
          </b-col>
          <b-col sm="12" lg="6" style="text-align: right;">
            <b-button variant="outline-primary" @click="f_systemUpdateByExcel()" :disabled="d_loadingSystemUpdate">
              <i class="fa fa-save"></i>
              {{ StoreLangTranslation.data['save'][StoreLang] }}
              <b-spinner v-if="d_loadingSystemUpdate" variant="primary" label="Spinning"></b-spinner>
            </b-button>
          </b-col>
        </b-row>
      </p>
    </b-modal>
  </div>
</template>

<script>
import store from '@/store';
import FrontendService from '@/services/frontend';
import SystemService from '@/services/system';
import { data as frd_webunit } from '@/options/frd_webunit';
import {
  default as PageInfo
} from '@/components/widgets/PageInfo';
import { mapGetters } from 'vuex';
export default {
  name: 'frontend_websiteunit_list',
  computed: mapGetters({
    StoreDevice: 'StoreDevice',
    StoreLang: 'StoreLang',
    StoreLangList: 'StoreLangList',
    StoreLangTranslation: 'StoreLangTranslation',
    StoreFrontendSettings: 'StoreFrontendSettings',
  }),
  components: {
    PageInfo,
  },
  props: {},
  data () {
    return {
      d_loadingSystemUpdate: false,
      d_systemUpdateByExcelModal: false,
      d_excelFileBase64: '',
      d_tmpFile: null,
      d_user: '',
      d_hoverIndex: '',
      d_searchText: '',
      d_frontendClientID: '',
      d_frontendList: [],
      d_frontendWebsiteunitList: [],
      d_frdWebUnitWdm: frd_webunit,
      d_frdWebunitData: {
        'click_type': '0',
        'is_dashboard': '0',
        'menu_caption': {},
        'page_top_caption': {},
        'parent_website_unit_no': {},
        'route_name': {},
        'route_path': {},
        'show_in_menu': '1',
        'status': '0',
        'website_unit_no': {},
        'page_info': [],
      }
    };
  },
  created: function () {
    this.d_user = JSON.parse(localStorage.getItem('user'));
    this.f_frontendList();
  },
  beforeMount () {},
  mounted: function () {
    // console.log(this.StoreFrontendSettings);
    // console.log(this.d_frdWebUnitWdm);
  },
  methods: {
    f_systemUpdateByExcel: function () {
      if (this.d_excelFileBase64) {
        this.d_loadingSystemUpdate = true;
        let query = 'frontend_client_id=' + this.d_frontendClientID;
        let data = { 'system_excel_file': this.d_excelFileBase64 };
        SystemService.system_update_by_excel(query, data)
          .then(resp => {
            this.d_loadingSystemUpdate = false;
            this.d_systemUpdateByExcelModal = false;
            if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
              alert(this.StoreLangTranslation.data['process_completed'][this.StoreLang]);
              location.reload();
            } else {
              alert(resp.data.status_code, ' ', resp.data.status_message);
            }
          });
      }
    },
    f_selectExcelDocument: function () {
      if (this.d_tmpFile) {
        // console.log(this.d_tmpFile);
        let file_name = this.d_tmpFile.name;
        let arr = this.d_tmpFile.name.split('.');
        let fileExtension = arr[arr.length - 1];
        if (['xlsx'].indexOf(fileExtension.toLowerCase()) === -1) {
          this.d_tmpFile = null;
          this.$forceUpdate();
          alert(this.StoreLangTranslation.data['file_extension_type_is_not_eligible'][this.StoreLang]);
          return;
        }
        if (this.d_tmpFile.size > 10485760) {
          this.d_tmpFile = null;
          this.$forceUpdate();
          alert(this.StoreLangTranslation.data['file_size_is_not_eligible'][this.StoreLang], ' : ', this.d_tmpFile.size);
          return;
        }
        var reader = new FileReader();
        var vm = this;
        var reader = new FileReader();
        reader.addEventListener("load", function () {
          // console.log(reader.result);
          // console.log('page_info_index ', page_info_index);
          // console.log('page_info_detail_index ', page_info_detail_index);
          // console.log('vm.d_frdWebunitData.page_info ', vm.d_frdWebunitData.page_info);
          vm.d_excelFileBase64 = reader.result;
          vm.$forceUpdate();
          // vm.f_applyFrontendSettings();
        }, false);
        reader.readAsDataURL(this.d_tmpFile);
        this.d_tmpFile = null;
        this.$forceUpdate();
      }
    },
    f_createAcceptedDocumentTypes: function () {
      let accept = '';
      let file_types = ['xlsx'];
      for (let i in file_types) {
        accept += '.' + file_types[i] + ',';
      }
      return accept;
    },
    f_newWebsiteUnit: function (websiteunit_client_id) {
      // this.$router.push({ path: '/frontend-webunit', query: { 'frontend_client_id': this.d_frontendClientID } });
      let url = window.location.origin + '/#/frontend-webunit?';
      url += 'frontend_client_id=' + this.d_frontendClientID;
      window.open(url);
      // window.open(url, '_target');
    },
    f_editThisWebsiteUnit: function (websiteunit_client_id) {
      let url = window.location.origin + '/#/frontend-webunit?';
      url += 'websiteunit_client_id=' + websiteunit_client_id;
      url += '&frontend_client_id=' + this.d_frontendClientID;
      window.open(url);
      // window.open(url, '_target');
      // this.$router.push({ path: '/frontend-webunit', query: { 'websiteunit_client_id': websiteunit_client_id, 'frontend_client_id': this.d_frontendClientID } });
    },
    f_searchWebsiteUnit: function (website_unit) {
      let res = false;
      if (this.d_searchText) {
        if (website_unit.w_id.toLocaleLowerCase().indexOf(this.d_searchText.toLocaleLowerCase()) !== -1) {
          res = true;
        }
      } else {
        res = true;
      }
      return res;
    },
    f_calculatePageInfoStyle: function (page_info_index) {
      let style = 'margin: 3px;';
      if (page_info_index % 2 === 0) {
        style += 'background-color: #99e4e8';
      } else {
        style += 'background-color: #99e4e8';
        // style += 'background-color: white;';
      }
      return style;
    },
    f_calculatePageInfoDetailStyle: function (page_info_detail_index) {
      let style = 'margin: 3px;';
      if (page_info_detail_index % 2 === 0) {
        style += 'background-color: #bcf1f4;';
      } else {
        style += 'background-color: white;';
      }
      return style;
    },
    f_addNewPageInfo: function () {
      let lang_eligible_variables = ['page_info_caption'];
      let new_page_info_item = { 'page_info_caption': {}, 'page_info_detail': [] };
      for (let k in lang_eligible_variables) {
        for (let lang_index in this.StoreLangList.data) {
          new_page_info_item[lang_eligible_variables[k]][this.StoreLangList.data[lang_index].value] = '';
        }
      }
      this.d_frdWebunitData.page_info.push(new_page_info_item);
    },
    f_addNewFrontendWebsiteUnit: function () {
      let query = 'frontend_client_id=' + this.d_frontendClientID;
      let data = { 'websiteunit': this.d_frdWebunitData };
      FrontendService.frontend_website_unit_new(query, data)
        .then(resp => {
          if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
            alert(this.StoreLangTranslation.data['process_completed'][this.StoreLang]);
          } else {
            alert(resp.data.status_code, ' ', resp.data.status_message);
          }
        });
    },
    f_frontendList: function () {
      let query = '';
      let data = {};
      FrontendService.frontend_list(query, data)
        .then(resp => {
          if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
            if (resp.data.list) {
              this.d_frontendList = resp.data.list;
              this.d_frontendClientID = this.d_frontendList[0].client_id;
            } else {
              alert(this.StoreLangTranslation.data['process_completed'][this.StoreLang]);
            }
          } else {
            alert(resp.data.status_code, ' ', resp.data.status_message);
          }
        });
    },
    f_frontendWebsiteunitList: function () {
      if (this.d_frontendClientID) {
        let query = 'frontend_client_id=' + this.d_frontendClientID;
        let data = {};
        FrontendService.frontend_websiteunit_list(query, data)
          .then(resp => {
            if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
              if (resp.data.list) {
                this.d_frontendWebsiteunitList = resp.data.list;
              } else {
                alert(this.StoreLangTranslation.data['process_completed'][this.StoreLang]);
              }
            } else {
              alert(resp.data.status_code, ' ', resp.data.status_message);
            }
          });
      }
    },
  },
  watch: {
    'd_frontendClientID': function () {
      this.f_frontendWebsiteunitList();
    }
  }
};

</script>

