import Vue from 'vue';
import { API_BASE_URL } from '@/config';
export default {
  system_update_by_excel (query, data) {
    let route = '';
    if (query) {
      route = 'v1/system/update/by_excel?' + query;
    } else {
      route = 'v1/system/update/by_excel';
    }
    return Vue.http.post(API_BASE_URL + route, data);
  }
};
